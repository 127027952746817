.badge,
.badgeRedesign,
.badgePropertyComparison {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  display: flex;
  align-items: center;
}

.badge {
  pointer-events: all;
  padding: var(--space-1x) var(--space-2x);
  background-color: var(--listing-card-badge-background-color);
  border-radius: var(--space-6x);
  color: var(--listing-card-badge-color);
  z-index: var(--z-index-2x);
  font-size: var(--font-size-sm);
}

.thankYouModalBadge.badge {
  z-index: var(--z-index-3x);
}

.infoIcon {
  font-size: var(--font-size-body);
}

.downArrowIcon {
  margin-right: var(--space-1x);
  font-size: var(--font-size-md);
  color: var(--badge-icon-color);
}

.badgePropertyComparison {
  color: var(--color-gray-500);
  background-color: var(--color-white);
  border-radius: var(--border-radius-2x);
  z-index: var(--z-index-2x);
  padding: var(--space-1x);
}

.caretAnchor {
  display: flex;
  --tooltip-info-icon-target-padding: var(--space-3-5x);
  --tooltip-info-icon-margin: calc(
    var(--tooltip-info-icon-target-padding) * -1
  );
  padding: var(--tooltip-info-icon-target-padding);
  margin: var(--tooltip-info-icon-margin) var(--tooltip-info-icon-margin)
    var(--tooltip-info-icon-margin)
    calc(var(--tooltip-info-icon-margin) + var(--space-1x));
}

.tooltip {
  font-weight: var(--font-weight-normal);
  line-height: var(--leading-normal);

  & strong {
    font-weight: var(--font-weight-semibold);
    color: var(--color-white);
  }
}
